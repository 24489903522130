//login api
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGGED_IN = "LOGGED_IN";
//----------------------------------------------------

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_SUCCESS = "POST_RESULT";
export const POST_FAILED = "POST_FAILED";
export const SUCCESS_USERS = "SUCCESS_USERS";
export const FAILED_USERS = "FAILED_USERS";

//---------------------Dashboard---------------------------------
export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILED = "DASHBOARD_FAILED";

//---------------------chart---------------------------------
export const GET_CHART_REQUEST = "GET_CHART_REQUEST";
export const CHART_SUCCESS = "CHART_SUCCESS";
export const CHART_FAILED = "CHART_FAILED";

//---------------------cnote---------------------------------
export const GET_CNOTE_REQUEST = "GET_CNOTE_REQUEST";
export const SEARCH_CNOTE_REQUEST = "SEARCH_CNOTE_REQUEST";
export const POST_CNOTE_REQUEST = "POST_CNOTE_REQUEST";
export const PUT_CNOTE_REQUEST = "PUT_CNOTE_REQUEST";
export const PATCH_CNOTE_REQUEST = "PATCH_CNOTE_REQUEST";
export const DELETE_CNOTE_REQUEST = "DELETE_CNOTE_REQUEST";
export const CNOTE_SUCCESS = "CNOTE_SUCCESS";
export const CNOTE_FAILED = "CNOTE_FAILED";
export const CNOTE_ISEXIST = "CNOTE_ISEXIST";

//---------------------consignor---------------------------------
export const GET_CNOR_REQUEST = "GET_CNOR_REQUEST";
export const POST_CNOR_REQUEST = "POST_CNOR_REQUEST";
export const PUT_CNOR_REQUEST = "PUT_CNOR_REQUEST";
export const PATCH_CNOR_REQUEST = "PATCH_CNOR_REQUEST";
export const DELETE_CNOR_REQUEST = "DELETE_CNOR_REQUEST";
export const SEARCH_CNOR_REQUEST = "SEARCH_CNOR_REQUEST";
export const CNOR_SUCCESS = "CNOR_SUCCESS";
export const CNOR_FAILED = "CNOR_FAILED";

//---------------------place---------------------------------
export const SEARCH_PLACE_REQUEST = "SEARCH_PLACE_REQUEST";
export const GET_PLACE_REQUEST = "GET_PLACE_REQUEST";
export const POST_PLACE_REQUEST = "POST_PLACE_REQUEST";
export const PUT_PLACE_REQUEST = "PUT_PLACE_REQUEST";
export const PATCH_PLACE_REQUEST = "PATCH_PLACE_REQUEST";
export const DELETE_PLACE_REQUEST = "DELETE_PLACE_REQUEST";
export const PLACE_SUCCESS = "PLACE_SUCCESS";
export const PLACE_FAILED = "PLACE_FAILED";

//------------------------------------------------------
export const GET_FINYEAR_REQUEST = "GET_FINYEAR_REQUEST";
export const POST_FINYEAR_REQUEST = "POST_FINYEAR_REQUEST";
export const PUT_FINYEAR_REQUEST = "PUT_FINYEAR_REQUEST";
export const PATCH_FINYEAR_REQUEST = "DELETE_FINYEAR_REQUEST";
export const DELETE_FINYEAR_REQUEST = "DELETE_FINYEAR_REQUEST";
export const FINYEAR_SUCCESS = "PLACE_SUCCESS";
export const FINYEAR_FAILED = "PLACE_FAILED";
//---------------------company---------------------------------
export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const POST_COMPANY_REQUEST = "POST_COMPANY_REQUEST";
export const PUT_COMPANY_REQUEST = "PUT_COMPANY_REQUEST";
export const PATCH_COMPANY_REQUEST = "PATCH_COMPANY_REQUEST";
export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST";
export const SEARCH_COMPANY_REQUEST = "SEARCH_COMPANY_REQUEST";
export const COMPANY_SUCCESS = "COMPANY_SUCCESS";
export const COMPANY_FAILED = "COMPANY_FAILED";
//---------------------transport---------------------------------
export const GET_TRANSPORT_REQUEST = "GET_TRANSPORT_REQUEST";
export const POST_TRANSPORT_REQUEST = "POST_TRANSPORT_REQUEST";
export const PUT_TRANSPORT_REQUEST = "PUT_TRANSPORT_REQUEST";
export const PATCH_TRANSPORT_REQUEST = "PATCH_TRANSPORT_REQUEST";
export const DELETE_TRANSPORT_REQUEST = "DELETE_TRANSPORT_REQUEST";
export const SEARCH_TRANSPORT_REQUEST = "SEARCH_TRANSPORT_REQUEST";
export const TRANSPORT_SUCCESS = "TRANSPORT_SUCCESS";
export const TRANSPORT_FAILED = "TRANSPORT_FAILED";
//---------------------invoice---------------------------------
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";
export const POST_INVOICE_REQUEST = "POST_INVOICE_REQUEST";
export const PUT_INVOICE_REQUEST = "PUT_INVOICE_REQUEST";
export const PATCH_INVOICE_REQUEST = "PATCH_INVOICE_REQUEST";
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const SEARCH_INVOICE_REQUEST = "SEARCH_INVOICE_REQUEST";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_FAILED = "INVOICE_FAILED";

//------------------Group-----------------------------------
export const GET_GROUP_REQUEST = "GET_GROUP_REQUEST";
export const POST_GROUP_REQUEST = "POST_GROUP_REQUEST";
export const PUT_GROUP_REQUEST = "PUT_GROUP_REQUEST";
export const PATCH_GROUP_REQUEST = "PATCH_GROUP_REQUEST";
export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const SEARCH_GROUP_REQUEST = "SEARCH_GROUP_REQUEST";
export const GROUP_SUCCESS = "GROUP_SUCCESS";
export const GROUP_FAILED = "GROUP_FAILED";

