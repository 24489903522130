import React, { useState } from 'react';
import { Container, Row, Col, Card, Modal, ListGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import CompanyDetails from './CompanyDetails';
import TransportForm from './TransportForm';
import { GET_TRANSPORT_REQUEST, DELETE_TRANSPORT_REQUEST } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import List from '../list/List';
import Loader from '../../components/loader/Loader';
function Transport(props) {
    let [more, setMore] = useState({});
    const [show, setShow] = useState(false);
    let [data, setData] = useState({});

    const renderAfterCalled = React.useRef(false);
    let dispatch = useDispatch();
    let transportState = useSelector(state => state.transport)
    const [rows, setRows] = React.useState(transportState.data)
    React.useEffect(() => {
        if (renderAfterCalled.current !== transportState.data.length) {
            dispatch({ type: GET_TRANSPORT_REQUEST, payload: 0 });
            setRows([...transportState.data])
            console.log("loading data")
        }
        renderAfterCalled.current = transportState.data.length
        return function cleanedUp() {
            console.log("cleaned up")
        }
        
    }, [dispatch, transportState])
    const onListClick = (action, data) => {
        console.log("data", data)
        if (action === "update") {
            setData(data)
            setShow(true)
        }
        if (action === "delete") {
            dispatch({
                type: DELETE_TRANSPORT_REQUEST, payload: data.id, callback: (data) => {
                    // console.log("..dele")
                    // let deldata = rows.filter(d => d.id !== data.id);
                    // console.log("..dele")
                    // setRows([...deldata])
                }
            })
        }
    }

    return (
        <Container fluid>
            <Loader loading={transportState.loading} />
            <Modal centered show={show} size="sm" onHide={() => {
                setShow(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TransportForm data={data} />
                </Modal.Body>
            </Modal>

            <Row>
                <Col md={3} className={"g-0"}>
                    <Container fluid>
                        <Row>
                            <Col><p className={"my-4 mb-2"}>Transport List</p></Col>
                        </Row>
                        <Row>
                            <Col><Card className={"p-1"}>
                                <ListGroup horizontal>
                                    <ListGroup.Item className={"w-100"}>
                                        <input type={"text"} placeholder={"search here..."}
                                            className={"search-box form-control form-control-sm"} />
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Icon.Plus style={{ fontSize: 20, cursor: "pointer" }} title={"Create New Company"}
                                            onClick={() => {
                                                setShow(true);
                                                setMore(null);
                                            }}
                                        />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='place-list'>

                                <div className={"overflow-auto"}>
                                    {rows && rows.map((data, i) => {
                                        //should not remove img key
                                        return <List onClick={onListClick} key={i} data={data} keys={["img", "transp"]} className={"mb-2 btn"} />;
                                    })}
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={7} className={"g-0"}>
                    <Container className={"h-90"}>
                        <CompanyDetails data={more} />
                    </Container>
                </Col>
                <Col md={2} className={"g-0"}>
                    <Container className={"h-90 pt-5"}>
                        <p className={"mt-4"}>Create company with user.</p>
                        <p>Company user have all permission under the company base.</p>
                        <p>Project creates under each company but project should be linked with user</p>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}
export default Transport