import React from 'react';
import EditTable from "../../components/table/EditTable";

const Cnote = (props) => {
    return (
        <div className={"position-relative"}>
            <EditTable/>
        </div>
    );
};
export default Cnote