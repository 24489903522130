import React from 'react';
import './fileupload.css'
import axios from "axios";
import {Button, ProgressBar, Image, Container, Row, Col, Stack} from 'react-bootstrap'

const FileUpload = (props) => {
    const fileRef = React.createRef()
    const [file, setFile] = React.useState(null)
    const [perc, setPerc] = React.useState(0)
    const [result, setResult] = React.useState('')
    const url = props.url || "/api/upload/";
    const handleInputChange = (event) => {
        setFile(event.target.files[0])
        setPerc(0)
    }

    const upload = () => {
        const data = new FormData()
        data.append('row_file', file)
        console.warn(file);
        axios.post(url, data, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setPerc(percentCompleted)
            }
        })
            .then(res => { // then print response status
                if (res.status == 200) {
                    setResult(res.data.row_file)
                }
                setTimeout(() => {
                    setPerc(0);
                }, 3000)
                if (props.callback){props.callback(res.status, res.data)}

            });
    }
    return (
        <div className="fileupload">
            <Container className="g-0">
                <Row className="g-1">
                    <Col md={8} className="g-1">
                        <div>Upload excel file</div>
                        <input type="file" onChange={handleInputChange} {...props}/>
                        <Stack direction="horizontal" gap={2}>
                            <Button size="sm" type="button" onClick={(e) => {
                                upload()
                            }}>Upload</Button>{perc !== 0 && <ProgressBar style={{height: 10,width:"100%"}} animated now={perc}/>}
                        </Stack>

                    </Col>
                    <Col className="g-1" md={4}>
                        <Image src={result}
                               thumbnail={true}
                               width="100%"/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FileUpload;