import axios from 'axios';

// const baseURL = "https://atpro.in/api/";
// const baseURL = "http://127.0.0.1:8000/api/";
const baseURL = "https://ceecee.atpro.in/api/";
axios.defaults.baseURL = baseURL
// Get All Todos
console.log("api initialized")
let user = JSON.parse(localStorage.getItem("auth"))
console.log("cached user", user)
if (user) {
    console.log("token applied", user)
    axios.defaults.headers.common['Authorization'] = "Token " + user.token
    console.log("header", axios.defaults.headers.common['Authorization'])
}
export const get = async (url) => {
    console.log("api called..",url)
    try {
        const todos = await axios.get(url)
        return todos.data;
    } catch (err) {
        console.log("mesasge",err)
        return { error: true, message: err.message }
    }
}

// Create New Todo
export const post = async (url, data) => {
    // axios.defaults.headers.common["content-type"] = 'multipart/form-data'
    console.log("post called", url)
    try {
        const todo = await axios.post(url, data);
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}
export const put = async (url, data) => {
    console.log("put called", url)
    try {
        const todo = await axios.put(url, data);
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}
//patch
export const patch = async (url, data) => {
    console.log("patch called", url)
    console.log(url)
    try {
        const todo = await axios.patch(url, data);
        return todo.data;
    } catch (err) {
        console.log("mesasge",err.response.data)
        return { error: true, message: err.response.data }
    }
}


// Delete existed todo
export const del = async (url, id) => {
    console.log(url, id)
    url = `${url}${id}/`
    try {
        await axios.delete(url)
        return id
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}