import React from "react";
import Avatar from "@mui/material/Avatar";
import "./List.css";
import { Button, ListGroup } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { getYYYMMDD } from "../../components/date/dates";

function List({selected, onShow, onClick, data, keys, actions, selectable }) {
    return (
        <>
            <ListGroup horizontal style={{ "cursor": "pointer", }} onClick={(e) => { onClick("selected", data) }}  >
                <ListGroup.Item className={'d-flex align-items-center ' + (selected===true?"active":"")}>
                    {selectable ? <input style={{opacity:0,width:0}}  type={selectable} name={"selected"} value={data.id}  /> :
                        <Avatar
                            style={{ height: "30px", width: "30px" }}
                            alt="Remy Sharp"
                        // src={data.img}
                        />}
                </ListGroup.Item>
                <ListGroup.Item className={"w-100 d-flex align-items-center"}>

                    <div className="details">
                        <div className={"mb-1 heading"}>{data[keys[1]] && data[keys[1]].toLowerCase()}</div>
                        <div className="date">{getYYYMMDD(data[keys[2]])}</div>
                        <div className="date">{data[keys[3]]}</div>

                    </div>
                </ListGroup.Item>
                {actions &&
                    <ListGroup.Item className={'d-flex align-items-center'}>
                        <Button className={"btn-light"}>
                            <Icon.PencilFill className={"rounded"} style={{ fontSize: "12px", color: "#3d6b10" }} onClick={() => { onClick("update", data) }} />
                        </Button>
                        <Button className={"btn-light"}>
                            <Icon.TrashFill style={{ fontSize: "12px", color: "red" }} onClick={(e) => { onClick("delete", data) }} />
                        </Button>
                        <Button className={"btn-light"}>
                            <Icon.ThreeDots style={{ fontSize: "12px" }} onClick={(e) => { onClick("more", data) }} />
                        </Button>
                    </ListGroup.Item>}
            </ListGroup>

        </>


    );
}

export default List;