import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { cdate } from '../date/dates'
import {
    SEARCH_CNOR_REQUEST,
    SEARCH_CNOTE_REQUEST,
    SEARCH_PLACE_REQUEST,
} from '../../redux/types';
export const newRow = (date) => {
    return { "autofocus": true, date: date, c_note: "", _consignor: "", consignor: 0, _consignee: "", _place: "", consignee: 0,invoice: 0, place: 0, weight: 0, no_of_pieces: 1, rate: 0, amount: 0, editable: true }
}
const   NewEleRow = ({ index, inputRow, onChange, onClickRow, selectable, hidecolums, actions, date, checked}) => {
    const [row, setRow] = React.useState(inputRow)
    hidecolums = hidecolums || []
    actions = actions || false;
    selectable = selectable || false
    let dispatch = useDispatch();
    let sl = (index + 1)
    let editable = row.editable
    let class_ = "form-control "
    let action = row.id ? "update" : "save";
    let i = -1
    React.useEffect(() => {
        if(date)
            inputRow["date"] = date
        inputRow["editable"] = row.editable
        inputRow["no_of_pieces"] = row.no_of_pieces
        inputRow.checked = checked
        setRow(inputRow)
    }, [date])
    React.useEffect(() => {
        if(date)
            inputRow["date"] = date
        inputRow["editable"] = row.editable
        inputRow["no_of_pieces"] = row.no_of_pieces
        inputRow.checked = checked
        setRow(inputRow)
    }, [])
    function newlist(source, ul, list, name, label) {
        list.map((l) => {
            let li = document.createElement("li")
            li.innerHTML = l[label]
            li.onclick = (e) => {
                row[source.target.name] = l[label]
                row[name] = l["id"]
                source.target.nextElementSibling.innerHTML = ""
                setRow({ ...row })
            }
            ul.appendChild(li)
            return ul
        });
    }
    function keyUpHandler(e) {
        let { key } = e
        if (e.target.name === 'c_note') {
            setTimeout(() => {
                if (e.target.value.length > 4) {
                    dispatch({
                        type: SEARCH_CNOTE_REQUEST, search: e.target.value, callback: (status) => {
                            if (status) {
                                e.target.classList.add("danger")
                                e.target.focus()
                            } else {
                                e.target.classList.remove("danger")
                            }
                        }
                    });
                }
            }, 300)
        }
        if (!e.target.nextElementSibling || key === 'ArrowDown' || key === 'ArrowUp') {
            return
        }
        if (e.target.nextElementSibling) e.target.nextElementSibling.innerHTML = ""
        i = -1
        let ul = document.createElement("ul")
        ul.classList.add("show-on-focus")
        
        ul.scrollIntoView()
        if (e.target.name === '_consignor') {
            e.target.nextElementSibling.appendChild(ul)
            dispatch({
                type: SEARCH_CNOR_REQUEST, payload: "/consignor/0/CNOR/?search=" + e.target.value, callback: (list) => {
                    newlist(e, ul, list, "consignor", "name")
                }
            })
        }
        if (e.target.name === '_consignee') {
            e.target.nextElementSibling.appendChild(ul)
            dispatch({
                type: SEARCH_CNOR_REQUEST, payload: "/consignor/0/CNEE/?search=" + e.target.value, callback: (list) => {
                    newlist(e, ul, list, "consignee", "name")
                }
            })
        }
        if (e.target.name === '_place') {
            e.target.nextElementSibling.appendChild(ul)
            dispatch({
                type: SEARCH_PLACE_REQUEST, payload: "/place/0/?search=" + e.target.value, callback: (list) => {
                    newlist(e, ul, list, "place", "place")
                }
            })
        }
    }
    function keyHandler(e) {
        let { key } = e
        console.log(key)
        if (e.target.nextElementSibling) {
            let li = e.target.nextElementSibling.getElementsByTagName("li")
            if (key === 'ArrowDown') {
                if (li[i]) li[i].classList.remove('active')
                i = i > li.length - 1 ? i = 1 : i + 1;
            }
            if (key === 'ArrowUp') {
                if (li[i]) li[i].classList.remove('active')
                i = i <= 0 ? 0 : i - 1;
            }
            if (li[i]) li[i].classList.add('active')
            if (li[i]) { li[i].focus(); li[i].scrollIntoView() }
            if (key === 'Enter') {
                if (li[i]) li[i].focus()
                if (li[i]) li[i].click()

            }
        }
        if (key === "Enter") {
            if (e.target.value && e.target.value.length > 0) {
                let nextBox = e.target.closest("td").nextElementSibling.firstChild
                try {
                    nextBox.focus();
                    nextBox.select();
                } catch (err) {
                    console.log(err)
                }
            }
        }
    }
    function handleClickOutside({ e, key }) {
        // document.
    }
    React.useEffect(() => {
        if (actions) {
            window.addEventListener("keydown", keyHandler);
            window.addEventListener("keyup", keyUpHandler);
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            if (actions) {
                document.removeEventListener("mousedown", handleClickOutside);
                window.removeEventListener("keydown", keyHandler);
                window.removeEventListener("keyup", keyUpHandler);
            }
        };
    });

    const calc = (e) => {
        let { key } = e
        let name = e.target.name
        if (key !== "Backspace") {
            if (name === 'rate' || name === 'weight' || name === "no_of_pieces") {
                if (row['weight'] && row['weight'].toString().toLowerCase() === 'm') {
                    row['amount'] = row['rate']
                } else {
                    row['amount'] = parseFloat(row['rate']) * parseFloat(row['weight'])
                }
            }
        }
    }

    const onChangeN = (index, e, row) => {
        row[e.target.name] = e.target.value
        calc(e)
        setRow({ ...row })
    }
    const onFocusN = (e, index) => {

    }
    const onKeyDownN = (e, index) => {

    }
    const onClickRowN = (row, index, action) => {
        console.log(action)
        if (action === 'update' && row["editable"]) {
            onClickRow(row, "update", (res) => {
                row["autoFocus"] = false
            })
        } else if (action === 'update') {
            row["editable"] = true;
            setRow({ ...row });
            return
        }
        onClickRow(row, action, (res) => {
            if (res.error) {
                row["editable"] = true
                row["autoFocus"] = true
            } else {
                row["editable"] = false
                row["autoFocus"] = false
            }
            // setRow({ ...row });
        })
    }
    const onCheckHandle = (e, row) => {
        row["checked"] = e.target.checked
        console.log("selected", e.target.checked)
        setRow({...row})
        if (onChange) onChange("checked", row)
    }


    return (
        <tr key={index} className='selectable'>
            <td width={50}>
                {selectable === true && <input type='checkbox' checked={row.checked} defaultChecked={checked} onChange={(e) => { onCheckHandle(e, row) }} className='checkbox' name={"ids"} value={row.id} />}
                {sl}
            </td>
            {!hidecolums.includes("date") && <td width={100}>
                <input
                    type={editable ? "date" : "text"}
                    className={class_}
                    value={row["date"]}
                    name={"date"}
                    disabled={!editable}
                    onChange={(e) => { row[e.target.name] = e.target.value; setRow({ ...row }) }}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {!hidecolums.includes("c_note") && <td width={100}><input
                onChange={(e) => { onChangeN(index, e, row) }}
                className={class_}
                value={row.c_note}
                name={"c_note"}
                disabled={!editable}
                autoComplete='off'
                onFocus={(e) => { onFocusN(e, index) }}
                onKeyDown={(e) => { onKeyDownN(e, index) }}
                autoFocus={row.autofocus}
            /></td>}
            {!hidecolums.includes("invoice") && <td width={80}>
                <input type="number"
                    min={1}
                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    value={row.invoice}
                    name={"invoice"}
                    disabled={!editable}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {!hidecolums.includes("_consignor") && <td><input
                onChange={(e) => { onChangeN(index, e, row) }}
                className={class_}
                value={row._consignor}
                name={"_consignor"}
                disabled={!editable}
                onFocus={(e) => { onFocusN(e, index) }}
                onKeyDown={(e) => { onKeyDownN(e, index) }}
                autoComplete='off'
            />
                <div></div>
            </td>}
            {!hidecolums.includes("_consignee") && <td><input
                onChange={(e) => { onChangeN(index, e, row) }}
                className={class_}
                value={row._consignee}
                name={"_consignee"}
                disabled={!editable}
                onFocus={(e) => { onFocusN(e, index) }}
                onKeyDown={(e) => { onKeyDownN(e, index) }}
                autoComplete='off'
            />
                <div></div>
            </td>}
            {!hidecolums.includes("_place") && <td width={100}>
                <input

                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    value={row._place}
                    name={"_place"}
                    disabled={!editable}
                    autoComplete='off'
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
                <div></div>
            </td>}
            {!hidecolums.includes("weight") && <td width={80}>
                <input
                    min={0}
                    type="text"
                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    value={row.weight}
                    name={"weight"}
                    disabled={!editable}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {!hidecolums.includes("no_of_pieces") && <td width={80}>
                <input type="number"
                    min={1}
                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    value={row.no_of_pieces}
                    name={"no_of_pieces"}
                    disabled={!editable}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {!hidecolums.includes("rate") && <td width={80}>
                <input
                    type="number"
                    min={0}
                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    value={row.rate}
                    name={"rate"}
                    disabled={!editable}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {!hidecolums.includes("amount") && <td width={80}>
                <input
                    type="number"
                    min={0}
                    onChange={(e) => { onChangeN(index, e, row) }}
                    className={class_}
                    style={{textAlign:"right"}}
                    value={row.amount}
                    name={"amount"}
                    disabled={!editable}
                    onFocus={(e) => { onFocusN(e, index) }}
                    onKeyDown={(e) => { onKeyDownN(e, index) }}
                />
            </td>}
            {actions === true ? (<td width={50}>
                <Button onClick={(e) => { onClickRowN(row, index, action); e.target.blur() }}>{editable ? <Icon.Check /> : <Icon.Pen />}</Button>
                <Icon.Trash onClick={() => { onClickRowN(row, index, "delete") }} className={"text-danger ml-1"} />
            </td>) : null}
        </tr>)
}
export default NewEleRow