import Form from "react-bootstrap/Form";
import React from "react";

const DateBox = (props,ref) => {
    const [error, setError] = React.useState('')
    let name = props.name || ""
    let _size = props.size || "sm"
    let label = props.label || name
    let placeholder = props.placeholder || label ? "Enter " + label : ""
    let message = props.message || placeholder
    let dv = props.defaultValue || ""

    return (
        <Form.Group>
            <div className="nav-item dropdown float-input">
                <Form.Control
                    required
                    type="date"
                    placeholder={placeholder}
                    size={_size}
                    {...props}
                    name={name}
                    defaultValue={dv}
                    ref={ref}
                />
                <Form.Label></Form.Label>
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </div>
        </Form.Group >
    )
}
export default React.forwardRef(DateBox)