import React, { useEffect, useState } from 'react';
import EditTable from "../../components/table/EditTable";
import { useSelector, useDispatch } from 'react-redux';
import { GET_CNOTE_REQUEST, GET_PLACE_REQUEST, GET_CNOR_REQUEST, POST_CNOTE_REQUEST, SEARCH_CNOTE_REQUEST } from '../../redux/types';
import { Col, Row, Form } from "react-bootstrap";
import BillTable from '../../components/table/BillTable';

const Invoice = (props) => {
    let dispatch = useDispatch();
    let cnoteSaga = useSelector(state => state.cnotes)
    const placeSate = useSelector(state => state.place)
    const cnorSate = useSelector(state => state.cnor)
    let [date, setDate] = useState()

    
    return (
        <div className={"p-5 position-relative"}>
            <BillTable/>
        </div>
    );
};
export default Invoice