import React, {useState} from 'react';
import "./main.css";
import { Container, Row, Col, Card, ListGroup, ButtonGroup, Form } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Avatar from "@mui/material/Avatar";
import Image from "../../components/image/Image";
function Profile({ data, keys }) {
    let keysObj = keys ? keys : {}
    let image = data[keysObj.hasOwnProperty("img") ? keysObj["img"] : "img"]
    let name = data[keysObj.hasOwnProperty("name") ? keysObj["name"] : "name"]
    let _date = data[keysObj.hasOwnProperty("date") ? keysObj["date"] : "date"]
    let phone = data[keysObj.hasOwnProperty("phone") ? keysObj["phone"] : "phone"]
    let email = data[keysObj.hasOwnProperty("email") ? keysObj["email"] : "email"]
    return (
        <>
            <ListGroup className={"mb-2"}>
                <ListGroup.Item>
                    <ListGroup horizontal>
                        <ListGroup.Item >
                            <Image cropShape={"round"} src={data[keys[0]]}/>
                        </ListGroup.Item>
                        <ListGroup.Item className={"pt-3"}>
                            <div className={"mb-1"}>{data[keys[1]]}</div>
                            <div>{data[keys[2]]}</div>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <ListGroup horizontal className="px-4 bg-light btn">
                                <ListGroup.Item className={"p-1 bg-light"}><Icon.Lock /></ListGroup.Item>
                                <ListGroup.Item className={'w-100 p-1 bg-light'}>
                                    <div>Change Password</div>
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>

                <ListGroup.Item>
                    <hr className={"mt-4"} />
                    <h6 className={"mt-2"}>Contact Details</h6>
                    <ListGroup horizontal className="px-4">
                        <ListGroup.Item className={"p-1"}>
                            <Icon.Phone style={{ fontSize: '16px' }} />
                        </ListGroup.Item>
                        <ListGroup.Item className={"p-1"}>
                            <div>{data[keys[3]]}</div>
                        </ListGroup.Item>
                    </ListGroup>
                    <ListGroup horizontal className="px-4">
                        <ListGroup.Item className={"p-1"}>
                            <Icon.Envelope />
                        </ListGroup.Item>
                        <ListGroup.Item className={"p-1"}>
                            <div>{data[keys[4]]}</div>
                        </ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item className={'d-flex align-items-center'}>
                    <p style={{ fontSize: "12px", color: "#7CFC00" }}>+${data.amount}</p>
                </ListGroup.Item>
            </ListGroup>
        </>
    )
}
export default Profile