import React, { useState } from 'react';
import "./company.css";
import { Container, Row, Col, Card, Modal, ListGroup } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import CompanyDetails from './CompanyDetails';
import CompanyForm from './CompanyForm';
import { DELETE_COMPANY_REQUEST, GET_COMPANY_REQUEST } from '../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import List from '../list/List';
function Company() {
    let [more, setMore] = useState({});
    let [data, setData] = useState({});
    const [show, setShow] = useState(false);
    const renderAfterCalled = React.useRef(false);
    let dispatch = useDispatch();
    let companyState = useSelector(state => state.company)
    const [rows, setRows] = React.useState(companyState.data)
    React.useEffect(() => {

        if (renderAfterCalled.current !== companyState.data.length) {
            dispatch({ type: GET_COMPANY_REQUEST, payload: 0 });
            // console.log(companyState.data);
            setRows(companyState.data)
            console.log("loading data")
        }
        renderAfterCalled.current = companyState.data.length;
        return function cleanup() {
            console.log("cleaned up")
        }
    }, [dispatch, companyState])

    const onListClick = (action, data) => {
        if (action === "update") {
            setData(data)
            setShow(true)
        }
        if (action === "delete") {
            dispatch({ type: DELETE_COMPANY_REQUEST, payload: data.id })
        }
        if (action === "more") {
            setMore(data)
        }
    }

    const onSearch = (e) => {
        dispatch({
            type: GET_COMPANY_REQUEST, payload: "company/0/?search=" + e.target.value, callback: (list) => {
                console.log("list",list)
                setRows([...list]);
            }
        })
    }

    return (
        <Container fluid>
            <Modal centered show={show} size="sm" onHide={() => {
                setShow(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CompanyForm data={data} />
                </Modal.Body>
            </Modal>

            <Row>
                <Col md={3} className={"g-0"}>
                    <Container fluid>
                        <Row>
                            <Col><p className={"my-4 mb-2"}>Company List</p></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={"p-1"}>
                                    <ListGroup horizontal>
                                        <ListGroup.Item className={"w-100"}>
                                            <input onChange={onSearch} type={"text"} placeholder={"search here..."}
                                                className={"search-box form-control form-control-sm"} />
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Icon.Plus style={{ fontSize: 20, cursor: "pointer" }} title={"Create New Company"}
                                                onClick={() => {
                                                    setShow(true);
                                                    //    setMore(null);
                                                }}
                                            />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='company-list'>
                                <div style={{ overflowY: "scroll" }} className="overflow-auto">
                                    {rows && rows.map((data, i) => {
                                        //should not remove img key
                                        return <List actions={true} onShow={setShow} onClick={onListClick} key={i} data={data} keys={["img", "name", "created_at", "phone"]} className={"mb-2 btn"} />;
                                    })}
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={7} className={"g-0"}>
                    <Container className={"h-90"}>
                        <CompanyDetails data={more} />
                    </Container>
                </Col>
                <Col md={2} className={"g-0"}>
                    <Container className={"h-90 pt-5"}>
                        <p className={"mt-4"}>Create company with user.</p>
                        <p>Company user have all permission under the company base.</p>
                        <p>Project creates under each company but project should be linked with user</p>

                    </Container>
                </Col>
            </Row>
        </Container>
    )
}
export default Company