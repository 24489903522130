import axios from "axios";
const TOKEN = "Bearer EABQnm55DEbwBAFrgm7Fd7Wce1SrvmVe171laCLMlQ8lvIetjEkDXoBmBcyafPKOEnOtXKW6pJPX7AOQP7BHNpYNZBGd2ywJwY4ZCS8mF4LwkIxs8l0eK2KguHAUDYMw1ZBTO26ZCvg4eJZCZA0GhzVWSPuNF1w1oeXWGWICILjnZCabvoyOyGs9dgrLeY4MOJkES44cZBi9vhAZDZD"
const PHONE_NUMBER_ID = '109516702107716'
const APP_ID = '5673048862822844'
const WHATS_APP_ID = "101761259558987"
const SYSTEM_USER = "vikas-user"

const get = (URL, payload, callback) => {
    axios.defaults.headers.common['Authorization'] = TOKEN
    axios.get(URL).then(response => {
        if (callback) callback(response.data)
    }).catch(error => {
        if (callback) callback(false)
        console.error(error)
    });
}
const post = (URL, payload, callback) => {
    axios.defaults.headers.common['Authorization'] = TOKEN
    axios.defaults.headers.common['ContentType'] = "application/json"
    axios.post(URL, payload).then(response => {
        if (callback) callback(response.data)
    }).catch(error => {
        if (callback) callback(false)
        console.error(error)
    });
}
//template_name = "birthday"
//parameters = [
//     {
//         "type": "text",
//         "text": name
//     }
// ]
export const post_message = (phone, template_name, parameters, callback) => {
    console.log("tempalte",template_name)
    phone = phone.replace("+", "")
    let payload =
    {
        "messaging_product": "whatsapp",
        "to": phone,
        "type": "template",
        "template": {
            "name": template_name,
            "language": {
                "code": "en",
                "policy": "deterministic"
            },
            "components": [
                {
                    "type": "body",
                    "parameters": parameters
                }
            ]
        }
    }
    let url = "https://graph.facebook.com/v16.0/"+PHONE_NUMBER_ID+"/messages"
    post(url, payload, callback);
}
export const get_templates = (callback) => {
    console.log("call get api")
    let url = "https://graph.facebook.com/v16.0/" + WHATS_APP_ID + "/message_templates"
    get(url, null, callback)
}