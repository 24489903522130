import React, {useState, useCallback, useEffect} from 'react';
import Cropper from 'react-easy-crop';
import {Form, Button, Badge, Modal} from 'react-bootstrap';
import {getOrientation} from 'get-orientation/browser';
import {getCroppedImg, getRotatedImage} from './CanvasUtils';
import './main.css';

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const ImageResize = ({fileEvent, cropShape, aspect, callback}) => {
    const [imageSrc, setImageSrc] = React.useState(null)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)


    useEffect(() => {
        if (fileEvent) {
            onFileChange(fileEvent);
        }
    }, [fileEvent])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
                rotation
            )
            console.log('donee', {croppedImage});
            setCroppedImage(croppedImage);
            if (callback) {
                callback(croppedImage);
            }
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCroppedImage(null);
    }, [])

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            console.log("image file:",file);
            let imageDataUrl = await readFile(file)
            try {
                // apply rotation if needed
                const orientation = await getOrientation(file)
                const rotation = ORIENTATION_TO_ANGLE[orientation]
                if (rotation) {
                    imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
                }
            } catch (e) {
                console.warn('failed to detect the orientation')
            }

            setImageSrc(imageDataUrl)
        }
    }

    return (
        <div>
            <div className={'crop-container'}>
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={aspect}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropShape={cropShape}
                    maxZoom={8}
                />
            </div>
            <div className={"controls"}>
                <div className={''}>
                    <Badge
                        variant="overline"
                    >
                        Zoom
                    </Badge>
                    <Form.Range
                        value={zoom}
                        min={1}
                        max={8}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e) => setZoom(e.target.value)}
                    />
                </div>
                <div className={''}>
                    <Badge
                        variant="overline"
                    >
                        Rotation
                    </Badge>
                    <Form.Range
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        onChange={(e) => setRotation(e.target.value)}
                    />
                </div>
                <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                >
                    OK
                </Button>
            </div>
        </div>
    )
}

function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export default ImageResize
