const ErrorParser = ({message,...props }) => {

    if (typeof message === 'object') {
        return Object.keys(message).map((key, i) => {
            if (typeof message[key] === 'object') {
                return Object.keys(message[key]).map((k, i) => {
                    return <div key={i} {...props} >{JSON.stringify(message[key][k])}</div>
                });
            }
        });
    } else {
        return <div {...props}>{JSON.stringify(message)}</div>
    }
}
export default ErrorParser