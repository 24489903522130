import React, { useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Dropdown, Button, Row, Col, Table, Container } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import * as Icon from 'react-bootstrap-icons';
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'
import SelectBox from '../../components/float-inputs/SelectBox'
import { get_templates, post_message } from "./services";

const HEADER_ROW = [
  {
    value: 'Name',
    fontWeight: 'bold'
  },
  {
    value: 'Date of Birth',
    fontWeight: 'bold'
  },
  {
    value: 'Cost',
    fontWeight: 'bold'
  },
  {
    value: 'Paid',
    fontWeight: 'bold'
  }
]

const DATA_ROW_1 = [
  // "Name"
  {
    type: String,
    value: 'John Smith'
  },

  // "Date of Birth"
  {
    type: Date,
    value: new Date(),
    format: 'mm/dd/yyyy'
  },

  // "Cost"
  {
    type: Number,
    value: 1800
  },

  // "Paid"
  {
    type: Boolean,
    value: true
  }
]

const data = [
  HEADER_ROW,
  DATA_ROW_1,
]

function Message() {
  let templateRef = useRef()
  let messageRef = useRef()
  let [contacts, setContacts] = React.useState([])
  let [templates, setTemplates] = React.useState([])
  let [params, setParams] = React.useState({})
  let [status, setStatus] = React.useState({})
  let [counts, setCounts] = React.useState(1)
  let [message, setMessage] = React.useState("")
  let [head, setHead] = React.useState([])
  let [customTemp, setCustomTemp] = React.useState([])
  let srcRef = React.useRef()

  React.useEffect(() => {
    get_templates((res) => {
      setTemplates(res.data)
    })
    setContacts([...contacts])
  }, [])

  React.useEffect(() => {
    get_templates((res) => {
      setTemplates(res.data)
    })
    setContacts([...contacts])
  }, [message])

  const readExcelFile = (e) => {
    readXlsxFile(e.target.files[0]).then((rows) => {
      let h = rows.shift()
      setHead(h)
      if (h[0].toLowerCase() === "contact") {
        setContacts([...rows])
      } else {
        alert("Please add contact in first column")
        return 0
      }
    })
  }
  function delay(t, data) {
    return new Promise(resolve => {
      setTimeout(resolve, t, data);
    });
  }
  const sendMessage = () => {
    let d = {};
    if (Object.keys(params).length == 0) {
      alert("Please select template")
      return
    }
    let errors = []
    Object.keys(params).map((param, i) => {
      if (params[param] === '') {
        errors.push(param);
      }
      return 0
    })

    if (errors.length > 0) {
      alert("Please select variable: " + errors.join(","))
      return
    }
    contacts.map(async (contact, i) => {
      let template = templateRef.current.value
      let varaibles = []

      varaibles = Object.keys(params).map((param, i) => {
        return {
          "type": "text",
          "text": contact[parseInt(params[param])]
        }
      })
      post_message(contact[0], template, varaibles, (s) => {
        d[contact[1]] = s;
        console.log("status", s, varaibles)
        setStatus({ ...d })
        setMessage(contact[0])
      });
      await delay(1000 * 10);

    })
    setContacts([...contacts])

  }
  const selectTemplate = (value, label) => {
    let temp = undefined;
    for (var i = 0; i < templates.length; i++) {
      if (templates[i].id === value) {
        temp = templates[i];
        break;
      }
    }
    if (temp) {
      let datas = temp.components
      let text = undefined
      for (var j = 0; j < datas.length; j++) {
        if (datas[j].type === "BODY") {
          text = datas[j].text;
          messageRef.current.value = text
          break;
        }
      }
      if (text) {
        let kv = {}
        let count = (text.match(/{{/g) || []).length
        for (var k = 0; k < count; k++) {
          kv["param" + k] = ""
        }
        console.log("count" + count)
        setParams({ ...kv })
      }
    }
  }
  const exportExcel = async () => {
    await writeXlsxFile(data, {
      //columns, // (optional) column widths, etc.
      fileName: 'file.xlsx'
    })
  }
  const onVariableChange = (index, v, l) => {
    params[("param" + index)] = v
    setParams({ ...params })
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={3}>
            <input type="file" id="input" onChange={readExcelFile} className="form-control form-control-md" />

          </Col>
          <Col md={3}>
            <SelectBox ref={templateRef} options={templates} keys={{ "label": "name", value: "id" }} onChange={selectTemplate} />
          </Col>
          <Col md={2}>
            <Button size="md" onClick={sendMessage}>Start Message</Button>
          </Col>
          <Col md={2}>
            <Button size="md" onClick={exportExcel}>Export</Button>

          </Col>

          <Col md={2}>
            <div class="alert alert-primary p-1" role="alert">
              Sending message to : {message}
            </div>
          </Col>
        </Row>
        <Row>
          {params && Object.keys(params).map((key, k) => {
            return <Col md={2}>
              Variable({k})
              <SelectBox ref={srcRef} onChange={(v, l) => { onVariableChange(k, v, l) }} options={head.map((h, i) => { return { label: h, value: i } })}
                keys={{ "label": "label", "value": "value" }}
              />
            </Col>
          })}
        </Row>
        <Row className="mt-3">
          <Col md={9}>

            <div style={{ height: "80vh", overflowY: "auto" }}>
              <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                <thead>

                  <tr>
                    <th width={20}><label style={{ width: 40 }}>SL No</label></th>
                    {head.map((h, i) => {
                      return <th key={i + "th"}>{h}</th>
                    })

                    }
                    <th width={20}>Status</th>
                    <th width={20}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((row, i) => {
                    console.log(status[row[1]] === undefined);
                    return (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        {row.map((r, i) => {
                          return <td key={i + "td"}>{r}</td>
                        })}
                        <td>{status[row[1]] === undefined ? "Pending" : (status[row[1]] ? "Success" : "Failed")}</td>
                        <td><Icon.Whatsapp className={status[row[1]] === undefined ? "text-info" : status[row[1]] ? "text-success" : "text-danger"} onClick={() => { sendMessage(row[0], row[1]) }} /></td>
                      </tr>
                    )
                  })}

                </tbody>
              </Table>
              {contacts.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
            </div>

          </Col>
          <Col md={3}>
            <div className="mb-3">

              <label htmlFor="exampleFormControlTextarea1" className="form-label">Taking first row for template </label>
              <textarea ref={messageRef} readOnly={true} className="form-control" id="exampleFormControlTextarea1"
                rows="3"></textarea>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Message;