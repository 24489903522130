import React, { useEffect, useState } from 'react';
import "./main.css";
import { Container, Row, Col, Card, Modal, ListGroup, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Profile from '../profile/Profile';
import { useSelector, useDispatch} from 'react-redux';
import {GET_USERS_REQUEST } from '../../redux/types';
import List from '../list/List';
import ProfileForm from "../profile/ProfileForm";

function User() {
    const renderAfterCalled = React.useRef(false);
    const [show, setShow] = useState(false);
    let [more, setMore] = useState({});
    let dispatch = useDispatch()
    const userState = useSelector(state => state.users);
    const [filteredUser, setFilteredUser] = useState(userState.data)
    useEffect(() => {
        if (renderAfterCalled.current !== userState.data.length) {
            dispatch({
                type: GET_USERS_REQUEST,
                payload: {}
            });
        }
        setFilteredUser(userState.data)
        renderAfterCalled.current = userState.data.length
    }, [])
    useEffect(() => {
        if (renderAfterCalled.current !== userState.data.length) {
            dispatch({
                type: GET_USERS_REQUEST,
                payload: {}
            });
        }
        setFilteredUser(userState.data)
        renderAfterCalled.current = userState.data.length
    }, [userState])
    return (

        <Container fluid>
            <Modal centered show={show} size="sm" onHide={() => {
                setShow(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProfileForm />
                </Modal.Body>
            </Modal>
            <Row>
                <Col md={12}>
                    <ListGroup horizontal className='title-bar'>
                        <ListGroup.Item><Icon.Envelope /> New Mail</ListGroup.Item>
                        <ListGroup.Item disabled><Icon.Trash3 /> Delete</ListGroup.Item>
                        <ListGroup.Item disabled> Archive</ListGroup.Item>
                        <ListGroup.Item disabled>Report</ListGroup.Item>
                        <ListGroup.Item disabled>Sweep</ListGroup.Item>
                        <ListGroup.Item disabled>Move to</ListGroup.Item>
                        <ListGroup.Item disabled>Reply all</ListGroup.Item>
                        <ListGroup.Item disabled className='w-100'> </ListGroup.Item>

                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col md={3} sm={12} xs={12} className={"g-0"}>
                    <Container fluid>

                        <Row>
                            <Col><p className={"my-2 mb-2"}>User List</p></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={"p-1"}>
                                    <ListGroup horizontal>
                                        <ListGroup.Item className={"w-100"}>
                                            <input style={{ paddingLeft: "15px" }} type={"text"}
                                                placeholder={"search here..."}
                                                // onChange={(e)=>{search(e.target.value)}}
                                                className={"search-box form-control form-control-sm "} />
                                        </ListGroup.Item>

                                        <span className="pt-3 px-2">
                                            {userState.loading && <Spinner />}
                                        </span>
                                        <ListGroup.Item>

                                            <Icon.Plus style={{ fontSize: 20, "cursor": "pointer" }}
                                                title={"Create New User"}
                                                onClick={() => {
                                                    setShow(true);
                                                    //    setMore(null);
                                                }} />

                                        </ListGroup.Item>
                                    </ListGroup>

                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ maxHeight: "72vh", overflowY: "auto" }} className='user-list'>

                                    {
                                        filteredUser.map((e, i) => {
                                            return (
                                                <List key={i} data={e}
                                                    keys={["img", "first_name", "phone", "email"]}
                                                    onClick={(data) => {
                                                        setMore(data)
                                                    }}/>
                                            )
                                        })
                                    }
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={5} sm={12} xs={12} className={"g-0"}>

                    <Container style={{ "marginTop": "3.5em" }}>
                        {(more && Object.keys(more).length > 0) &&
                            <div className={"h-80 d-flex justify-content-center"}>
                                <Card className='w-100' style={{ "background": "none" }}>
                                    <Profile data={more} keys={["img","name"]} />
                                </Card>
                            </div>}
                        {(more === null || (more && Object.keys(more).length === 0)) &&
                            <div className={"h-80 d-flex align-items-center justify-content-center bg-light"}>
                                <p className={"text-center"}>Please select any hade to view profile details</p>
                            </div>}

                    </Container>

                </Col>
                <Col md={4} sm={12} xs={12} className={"g-0"}>
                    <Container className={"h-90 pt-5"}>
                        <p className={"mt-4"}>Create User with credentials.</p>
                        <p>User have all permission under the permission base.</p>
                        <p>User creates under each company but company should be linked with user</p>
                    </Container>
                </Col>
            </Row>

        </Container>
    )
}

export default User