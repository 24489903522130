import React, { useState } from 'react'
import "./company.css";
import { Container, Row, Col, Card, Button, Modal, FormGroup, Form, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import TextBox from "../../components/float-inputs/TextBox";
import PasswordBox from "../../components/float-inputs/PasswordBox";
import SelectBox from "../../components/float-inputs/SelectBox";
import XForm from "../../components/float-inputs/XForm";
// import Image from "../../components/image/Image";
// import Avatar from "@mui/material/Avatar";
// import { useNavigate } from "react-router-dom";
import { PATCH_CNOR_REQUEST, POST_CNOR_REQUEST, GET_CNOR_REQUEST } from '../../redux/types';
import { useSelector, useDispatch } from 'react-redux';
// import ContactsForm from './ContactsForm'
// import TaxForm from './TaxForm';
// import { Box, Paper } from '@mui/material';
// import { useTheme } from '@emotion/react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Loader from '../../components/loader/Loader';

function ConsignorForm(props) {
    let { data } = props
    let dispatch = useDispatch()
    let userSaga = useSelector(state => state.users)
    let cnorSaga = useSelector(state => state.cnor)
    const [activeStep, setActiveStep] = useState(0);
    const [prevData, setPrevData] = useState(data)
    const [message, setMessage] = useState(undefined)
    return (
        <>
            <div className='d-flex justify-content-between pt-2'>
                <div>Enter Consigner Details</div><div><Loader loading={cnorSaga.loading} /></div>
                <p>{message}</p>

            </div>
            {
                activeStep == 0 &&
                <XForm name={"form1"} data={prevData} callback={(e, firstData) => { setActiveStep(1); setPrevData(firstData); e.preventDefault(); }}>

                    <TextBox type={"hidden"} name={"company"} value={1} />
                    <TextBox maxLength={100} label={"Name"} name={"name"} required={data.id ? false : true} />
                    <TextBox maxLength={100} label={"Address 1"} name={"address1"} required={false} />
                    <TextBox maxLength={100} label={"Address 2"} name={"address2"} required={false} />
                    <TextBox maxLength={100} label={"Place"} name={"place"} required={false} />
                    <TextBox maxLength={100} label={"Pin"} name={"pin"} required={false} />
                    <TextBox maxLength={10} label={"Phone"} name={"phone"} required={false} />
                    <div className='d-flex pt-2 justify-content-between'>
                        <Button disabled="true">Prev</Button>
                        <Button type={"submit"}>Next</Button>
                    </div>
                </XForm>
            }
            {
                activeStep == 1 &&
                <XForm data={data} name={"form2"} callback={(e, d) => {
                    console.log("form name", e.target.name, " id =", data.id)
                    if (e.target.name === "form2") {
                        let combineData = { ...prevData, ...d }
                        let postData = { "id": data.id }
                        Object.keys(combineData).map((key) => {
                            if (String(combineData[key]).trim() !== "") {
                                postData[key] = combineData[key]
                            }
                        })
                        console.log("updating data:", postData)
                        if (data.id) {
                            console.log("patching...")
                            dispatch({
                                type: PATCH_CNOR_REQUEST,
                                payload: postData,
                                callback:(res)=>{
                                    if(res.error){
                                        setMessage(res.message)
                                    }else{
                                        setMessage("Successfully updated")
                                    }
                                    dispatch({type:GET_CNOR_REQUEST,payload:0})
                                }
                            })
                        } else {
                            console.log("posting...")
                            dispatch({
                                type: POST_CNOR_REQUEST,
                                payload: combineData,
                            })
                        }
                    }
                }}>
                    <TextBox maxLength={30} label={"Fax"} name={"pan"} required={false} />
                    <TextBox maxLength={30} label={"Email"} name={"email"} required={false} />
                    <TextBox maxLength={30} label={"Gst Id"} name={"gst_id"} required={false} />
                    <TextBox type={"hidden"} maxLength={100} label={"Tax Type"} name={"tax_type"} value={1} />
                    {/* <TextBox maxLength={100} label={"Type"} name={"type"} required={false} /> */}
                    <SelectBox options={[
                        { "value": "CNOR", "label": "Consignor" },
                        { "value": "CNEE", "label": "Consignee" }]}
                        keys={{"value":"value","label":"label"}} name={"type"}/>
                    <div className='d-flex justify-content-between pt-2'>
                        <Button onClick={() => { setActiveStep(0) }}>Prev</Button>
                        <Button type={"submit"}>Submit</Button>
                    </div>
                </XForm>
            }
        </>
    )
}
export default ConsignorForm