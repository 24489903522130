import React, { useState } from 'react'
// import "./company.css";
import { Container, Row, Col, Card, Button, Modal, FormGroup, Form, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import TextBox from "../../components/float-inputs/TextBox";
// import PasswordBox from "../../components/float-inputs/PasswordBox";
import XForm from "../../components/float-inputs/XForm";
// import Image from "../../components/image/Image";
// import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import { POST_PLACE_REQUEST} from '../../redux/types';
import { useSelector, useDispatch } from 'react-redux';
// import ContactForm from './ContactForm'
// import Banking from './Banking';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Loader from '../../components/loader/Loader';
function PlaceForm(props) {
    let { data } = props
    let [step, setStep] = React.useState(0);
    let [prevData, setPrevData] = React.useState({});
    let userSaga = useSelector(state => state.users)
    let placeSaga = useSelector(state => state.place)
    let dispatch = useDispatch()
    return (
        <>
        <p>{placeSaga.message}</p>
        <div className='d-flex justify-content-between pt-2'>
        <div>Enter Place Details</div><div><Loader loading={placeSaga.loading}/></div>
        </div>
            
            <XForm callback={(e, data) => {
                let combineData = {...data}
                // console.log("Combine data:" ,combineData)
                dispatch({
                    type: POST_PLACE_REQUEST,
                    payload: data
                    })
                }}>
                <TextBox maxLength={100} label={"Region Name"} name={"region_name"}/>
                <TextBox maxLength={100} label={"District"} name={"district"}/>
                <TextBox maxLength={100} label={"Place"} name={"place"}/>
                <TextBox maxLength={50} label={"Region Coverage"} name={"region_coverage"}/>
                <TextBox maxLength={50} label={"Region Letter"} name={"region_letter"}/>
                <Button variant="primary" type="submit" className={"mt-2 "}>Submit</Button>
                <div className='d-flex justify-content-between pt-2'>
                    
                    
                </div>
            </XForm>
            
        </>
    )
}

export default PlaceForm