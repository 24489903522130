import React from "react";
import {Button} from 'react-bootstrap'
// import {set_cptable} from "https://cdn.sheetjs.com/xlsx-0.19.3/package/xlsx.mjs";
// import * as cptable from 'https://cdn.sheetjs.com/xlsx-0.19.3/package/dist/cpexcel.full.mjs';
// set_cptable(cptable);

const rows = [
  { name: "George Washington", birthday: "1732-02-22" },
  { name: "John Adams", birthday: "1735-10-19" },
  // ... one row per President
]
const ExportExcel = (props) => {
    // const exportXLs = async () => {
    //     const XLSX = await import("https://cdn.sheetjs.com/xlsx-0.19.3/package/xlsx.mjs");
    //     const worksheet = XLSX.utils.json_to_sheet(rows);
    //     const wb = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
    //     XLSX.writeFile(wb, "SheetJSESMTest.xlsx");
    // }
    return (
        <span className={"btn"}>{props.children}</span>
    );
}
export default ExportExcel