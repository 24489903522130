import React, {useState} from 'react'
import "./main.css";
import {Container, Row, Col, Card, Button, Modal, FormGroup, Form, Spinner, ListGroup} from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import TextBox from "../../components/float-inputs/TextBox";
import PasswordBox from "../../components/float-inputs/PasswordBox";
import XForm from "../../components/float-inputs/XForm";
import Image from "../../components/image/Image";
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router-dom";
import {POST_USER_REQUEST} from '../../redux/types';
import {useSelector, useDispatch} from 'react-redux';
import FormImageController from '../../components/image/FormImageController';

function ProfileForm(props) {
    let {data} = props
    let dispatch = useDispatch()
    let userSaga = useSelector(state => state.users)
    return (
        <>
            <p>{userSaga.message}</p>
            <XForm className='' callback={(e, data) => {
                console.log((JSON.stringify(data)));
                dispatch({
                    type: POST_USER_REQUEST,
                    payload: data,
                })
            }}>

                <Image name={"avtar"} cropShape={"round"}/>
                <TextBox label={"First Name"} name={"first_name"}/>
                <TextBox label={"Last Name"} name={"last_name"}/>
                <hr/>
                <TextBox label={"Phone Number"} name={"phone"}/>
                <hr/>
                <TextBox label={"Email Id"} name={"email"}/>
                <hr/>
                <TextBox label={"Username"} name={"username"}/>
                <PasswordBox label={"Password"} name={"password"}/>
                <Form.Group className="d-flex align-items-center">
                    <Button className={'mt-3 px-4'} as="input" type="submit" value="Submit"/>
                    <span className="pt-3 px-2">
                        {userSaga.loading && <Spinner/>}
                    </span>
                </Form.Group>
            </XForm>
        </>
    )
}

export default ProfileForm