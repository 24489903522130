import * as type from '../types';
const storeName = "auth"
const initialState = {
    data: JSON.parse(localStorage.getItem(storeName)) || {},
    loading: false,
    error: null,
};

export default function auth(state = initialState, action) {
    // console.log("action", action.type)
    switch (action.type) {
        case type.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case type.LOGIN_SUCCESS:
            localStorage.setItem(storeName, JSON.stringify(action.data))
            return {
                ...state,
                data: JSON.parse(localStorage.getItem(storeName)),
                loading: false,
                error: null
            };
        case type.LOGIN_FAILED:
            return {
                ...state,
                data: {},
                loading: false,
                error: action.error
            };
        case type.LOGOUT:
            localStorage.setItem(storeName,null)
            return {
                ...state,
                data: {},
                loading: false,
                error: null
            };
        default:
            return state;
    }
}