import React from "react";
import { Table } from "@mui/material";
import * as Icon from 'react-bootstrap-icons';
import List from "../list/List";
import { Button, Row, Col, Container, Card, ListGroup, Modal } from 'react-bootstrap'
import SelectBox from "../../components/float-inputs/SelectBox";
import TextBox from "../../components/float-inputs/TextBox";
import DateBox from "../../components/float-inputs/DateBox";
import Loader from "../../components/loader/Loader";
import './main.css';
import { useSelector, useDispatch } from 'react-redux';
import NewEleRow from '../../components/table/NewEleRow';
import { cdate, endOfMonth, getYYYMMDD } from '../../components/date/dates'
import Swal from 'sweetalert2'
import BillTable from "../../components/table/BillTable";
import { Print } from "../../components/print/Print";
import writeXlsxFile from 'write-excel-file'
import ErrorParser from "../../components/ErrorParser";

import {
    GET_CNOR_REQUEST,
    GET_CNOTE_REQUEST,
    GET_TRANSPORT_REQUEST,
    SEARCH_CNOR_REQUEST,
    POST_INVOICE_REQUEST,
    PATCH_CNOTE_REQUEST
} from '../../redux/types';
//R60115559

function Bills(props) {
    const renderAfterCalled = React.useRef(false);
    const fromRef = React.useRef();
    const toRef = React.useRef();
    const yearRef = React.useRef(localStorage.year);
    const invoiceNoRef = React.useRef();
    const invoiceDateRef = React.useRef();
    const descRef = React.useRef();
    let dispatch = useDispatch();
    let cnoteState = useSelector(state => state.cnotes)
    let cnorState = useSelector(state => state.cnor)
    const [rows, setRows] = React.useState(cnoteState.data)
    const [editRows, setEditRows] = React.useState([])
    const [exportRows, setExportRows] = React.useState([])
    const [consignor, setConsignor] = React.useState(cnorState.data)
    const [years, setYears] = React.useState([])
    const [cnor, setCnor] = React.useState("")
    const [loader, setLoader] = React.useState(false)
    const [showInvoice, setShowInvoice] = React.useState(false)
    const [invoices, setInvoices] = React.useState([])
    const [total, setTotal] = React.useState(0)
    const [cgst, setCgst] = React.useState(localStorage.cgst ? localStorage.cgst : 0)
    const [sgst, setSgst] = React.useState(localStorage.sgst ? localStorage.sgst : 0)
    const [other, setOther] = React.useState(localStorage.other ? localStorage.other : 0)
    const [bill, setBill] = React.useState({})
    const [showBill, setShowBill] = React.useState(false)
    const [showEdit, setShowEdit] = React.useState(false)
    const [showExport, setShowExport] = React.useState(false)
    let totalAmount = 0

    const init = () => {
        if (renderAfterCalled.current !== cnorState.data.length) {
            dispatch({ type: GET_CNOR_REQUEST, payload: 0 });
            dispatch({ type: GET_CNOTE_REQUEST, payload: 0 });
            dispatch({ type: GET_TRANSPORT_REQUEST, payload: 0 });
            getData()
        }
        console.log("local :", localStorage.year)
        setRows(rows)
        if (localStorage.year) {
            getInvoiceNumber(localStorage.year)
        }
        renderAfterCalled.current = cnorState.data.length
        getData()
    }
    const getData = () => {
        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: "/invoice/0/", callback: (list) => {
                setYears([...list]);
            }
        })
    }
    React.useEffect(() => {
        init()
        return function cleanup() {
            console.log("cleaned up")
        }
    }, [])
    React.useEffect(() => {
        init()
        return function cleanup() {
            console.log("cleaned up")
        }
    }, [cnorState])
    React.useEffect(() => {
        calcTax()
    }, [cnor, cgst, sgst])


    const onListClick = (action, data) => {
        setCnor(data.id)
        console.log(cnor, data.id)

    }
    function forceUpdate() {
        setRows(prev => {
            return [...prev]
        })
    }
    const onClickRow = (data, action, callback) => {
        console.log("Submitted data:", data)
        if (data.id) {
            dispatch({
                type: PATCH_CNOTE_REQUEST, payload: data, callback: (res) => {
                    if (res) {
                        callback(res)
                        dispatch({ type: "CNOTE_FAILED", data: { error: false, message: `${res.c_note} successfully updated` } });
                    } else {
                        dispatch({ type: "CNOTE_FAILED", data: { error: true, message: `Updation failed :${res.message}` } });
                    }
                    forceUpdate()
                }
            });
        }

    }
    const onSearch = (e) => {
        setLoader(true)
        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: "/consignor/0/CNOR/?search=" + e.target.value, callback: (list) => {
                setConsignor([...list]);
                setLoader(false)
            }
        })
    }
    const getCnotes = (e) => {
        setLoader(true)
        setRows([...[]]);
        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: `/cnote/consignor/0/${fromRef.current.value}/${toRef.current.value}/${cnor}/`, callback: (list) => {
                setRows([...list]);
                setLoader(false)
            }
        })
    }
    const getInvoiceNumber = (year) => {
        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: `/year/0/${year}/`, callback: (list) => {

                if (list && list.length >= 1) {
                    invoiceNoRef.current.value = String((parseInt(list[0].number) + 1)).padStart(3, '0')
                }
            }
        })
    }
    const onRowChange = (action, row) => {
        rows.map((r, i) => {
            if (r.id === row.id) {
                rows[i].checked = row.checked
                setRows([...rows])
            }
        })
    }
    const saveInvoice = (e) => {
        setRows([...rows])
        let checkedIds = []
        rows.map((r) => { if (r.checked) checkedIds.push(r.id); return 0; })
        console.log(checkedIds)
        let msg = {
            backdrop: false,
            icon: "warning",
            text: "There is no data/selected to make an invoice.",
            showCancelButton: false,
            confirmButtonText: 'OK',
            isConfirmed: true,
        }
        if (checkedIds.length === 0) {
            Swal.fire(msg)
            return
        }
        let data = {
            fin_year: yearRef.current.value,
            from: fromRef.current.value,
            to: toRef.current.value,
            cnor: cnor,
            cgst: cgst,
            sgst: sgst,
            other: other,
            invoice_date: invoiceDateRef.current.value,
            invoice_number: invoiceNoRef.current.value,
            ids: checkedIds

        }
        console.log(" saving data:", data)
        let errorTranform = {
            "fin_year": "Financial Year",
            "cnor": "Consignor", "from": "From Date", "to": "To Date",
            "invoice_date": "Invoice Date"
        }

        let errorText = Object.keys(data).filter((key) => {
            return data[key] === "" || data[key] == undefined
        })
        console.log(" erorr data:", errorText)
        if (errorText.length > 0) {
            msg.text = "Please fill  " + errorText.map(key => errorTranform[key]).join(", ")
            Swal.fire(msg)
            return
        }
        data["desc"] = descRef.current.value ? descRef.current.value : "";
        msg.text = 'Do you want to continue'
        msg.showCancelButton = true
        Swal.fire(msg).then((result) => {
            if (result['isConfirmed']) {
                dispatch({
                    type: POST_INVOICE_REQUEST, payload: data, callback: (list) => {
                        setRows([...[]])
                        if (list && list.length >= 1) {
                            invoiceNoRef.current.value = String((parseInt(list[0].number) + 1)).padStart(3, '0')
                        }
                    }
                })
            }
        })
    }
    const getInvoice = (e) => {

        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: `invoice/print/0/${fromRef.current.value}/${toRef.current.value}/`, callback: (list) => {
                console.log(list)
                setInvoices([...list]);
                setShowInvoice(true)
                setShowBill(false)
            }
        })

    }
    const percentage = (total, percentage) => {
        return parseFloat((total / 100) * percentage).toFixed(2)
    }
    const calcTax = (totalAmount, sgstPerc, cgstPerc, other) => {
        let t = parseFloat(totalAmount) + parseFloat(sgstPerc) + parseFloat(cgstPerc) + parseFloat(other ? other : 0)
        return t.toFixed(2)
    }
    const fetchDataByInvoiceNumber = (number, isSearch) => {
        setLoader(true)
        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: `invoice/number/${parseInt(number)}/`, callback: (list) => {

                if (list.length > 0) {
                    if (isSearch) {
                        setRows([...list[0].cnotes])
                        setShowInvoice(false)
                        setShowBill(false)
                    } else {
                        console.log("bill", list[0])
                        setBill({ ...list[0] });
                        setShowInvoice(false)
                        setShowBill(true)
                    }
                }
                setLoader(false)
            }
        })
    }
    const fetchDataForEdit = () => {
        setRows([...rows])
        let checkedRows = []
        rows.map((r) => { if (r.checked) checkedRows.push(r); return 0; })
        let msg = {
            backdrop: false,
            icon: "warning",
            text: "There is no data/selected to make an edit.",
            showCancelButton: false,
            confirmButtonText: 'OK',
            isConfirmed: true,
        }
        if (checkedRows.length === 0) {
            Swal.fire(msg)
            return
        }
        console.log(checkedRows)
        setEditRows([...checkedRows])
        setShowEdit(true)

    }
    const fetchDataForExport = () => {

        dispatch({
            type: SEARCH_CNOR_REQUEST, payload: `/invoice/export/0/${fromRef.current.value}/${toRef.current.value}/`, callback: (list) => {
                console.log(list)
                setExportRows([...list]);
                setLoader(false)
                setShowExport(true)
            }
        })

    }
    const downloadExport = async () => {
        const HEADER_ROW = [
            {
                value: 'SL No',
                fontWeight: 'bold'
            },
            {
                value: 'CUSTOMER',
                fontWeight: 'bold',
                width: 300
            },
            {
                value: 'GST NO',
                fontWeight: 'bold',
                width: 100
            },
            {
                value: 'INVOICE',
                fontWeight: 'bold'
            },
            {
                value: 'Date',
                fontWeight: 'bold'
            },
            {
                value: 'AMOUNT',
                fontWeight: 'bold'
            },
            {
                value: 'CGST',
                fontWeight: 'bold'
            },
            {
                value: 'SGST',
                fontWeight: 'bold'
            },
            {
                value: 'GRAND',
                fontWeight: 'bold'
            },
        ]
        let datas = [
            HEADER_ROW,
        ]
        let hasRow = false
        exportRows.map((data, i) => {
            if (data.checked) {
                hasRow = true
                datas.push([
                    {
                        column: 'SL NO.',
                        type: Number,
                        value: (i + 1)
                    },
                    {
                        column: 'CUSTOMER',
                        width: 300,
                        type: String,
                        value: data._consignor
                    },
                    {
                        column: 'GST NO',
                        type: String,
                        value: String(data._gst_no)
                    },
                    {
                        column: 'INVOICE',
                        type: Number,
                        value: data.number
                    },
                    {
                        column: 'Date',
                        type: String,
                        value: data.invoice_date
                    },
                    {
                        column: 'AMOUNT',
                        type: String,
                        value: parseFloat(data._calc_data.total).toFixed(2)
                    },
                    {
                        column: 'CGST',
                        type: String,
                        value: parseFloat(data._calc_data.cgst).toFixed(2)
                    },
                    {
                        column: 'SGST',
                        type: String,
                        value: parseFloat(data._calc_data.sgst).toFixed(2)
                    },

                    {
                        column: 'GRAND',
                        type: String,
                        value: parseFloat(data._calc_data.grand).toFixed(2)
                    },
                ])
            }
        })
        if (hasRow) {
            await writeXlsxFile(datas, {
                //columns, // (optional) column widths, etc.
                fileName: cdate() + '_bills.xlsx'
            })
        } else {
            let msg = {
                backdrop: false,
                icon: "warning",
                text: "There is no data/selected to download.",
                showCancelButton: false,
                confirmButtonText: 'OK',
                isConfirmed: true,
            }
            Swal.fire(msg)
        }

    }

    return (
        <>
            <Modal backdrop="static" centered show={showInvoice} size="lg" onHide={() => {
                setShowInvoice(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                            <thead>
                                <tr>
                                    <th width={50}>SL No</th>
                                    <th width={100}>Financial Year</th>
                                    <th width={90}>Invoice Number</th>
                                    <th>Description</th>
                                    <th>sess</th>
                                    <th width={70}>Consignor</th>
                                    <th width={70}>Date</th>
                                    <th width={70}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoices.map((invoice, i) => {
                                    return (<tr>
                                        <th width={50}>{i + 1}</th>
                                        <td width={100}>{invoice.fin_year}</td>
                                        <td width={90}>{invoice.number}</td>
                                        <td>{invoice.description}</td>
                                        <td>{invoice.sess}</td>
                                        <td width={70}>{invoice._consignor}</td>
                                        <td width={70}>{getYYYMMDD(invoice.invoice_date)}</td>
                                        <td width={70}><Icon.Printer onClick={() => { fetchDataByInvoiceNumber(invoice.number, false) }} /></td>
                                    </tr>)
                                })}

                            </tbody>
                        </Table>
                        {rows.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal backdrop="static" centered show={showBill} size="lg" onHide={() => {
                setShowBill(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Print>
                        <BillTable rows={bill.cnotes} company={bill.company} consignor={bill.cnor} invoice={bill.invoice} />
                    </Print>
                </Modal.Body>
            </Modal>
            <Modal backdrop="static" centered show={showEdit} size="xl" onHide={() => {
                setShowEdit(false)
                getCnotes("")
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        
                            <ErrorParser className={"mt-2 " + (cnoteState.success ? "text-success" : "text-danger")} message={cnoteState.message} />
                        
                        <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                            <thead>
                                <tr>
                                    <th width={50}>SL No</th>
                                    <th width={100}>Date</th>
                                    <th width={90}>CNote</th>
                                    <th>Invoice</th>
                                    <th>Consignor</th>
                                    <th>Consignee</th>
                                    <th>Place</th>

                                    <th width={70}>Weight</th>
                                    <th width={70}>No of Pieces</th>
                                    <th width={80}>Rate</th>
                                    <th width={70}>Amount</th>
                                    <th colSpan={2} width={30}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editRows && editRows.map((row, i) => {
                                    row.checked = row.checked == false ? false : true
                                    row["weight"] = row.weight == 0.25 ? "M" : row.weight
                                    return <NewEleRow key={i}
                                        index={i}
                                        inputRow={row}
                                        onClickRow={onClickRow}
                                        selectable={false}
                                        onChange={onRowChange}
                                        checked={row.checked}
                                        actions={true}

                                    />
                                })
                                }

                            </tbody>
                        </Table>
                        {rows.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
                    </div>
                </Modal.Body>
            </Modal>
            <Modal backdrop="static" centered show={showExport} size="xl" onHide={() => {
                setExportRows([...[]])
                setShowExport(false)
            }}>
                <Modal.Header closeButton backdrop={"false"}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Export Data <Button onClick={downloadExport}>Export</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ height: "80vh", overflowY: "auto" }}>
                        <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                            <thead>
                                <tr>
                                    <th width={30}>SL No</th>
                                    <th width={200}>CUSTOMER</th>
                                    <th width={80}>GST NO</th>
                                    <th width={50}>Invoice</th>
                                    <th width={50}>Date</th>
                                    <th width={50}>Amount</th>
                                    <th width={50}>CGST</th>
                                    <th width={50}> SGST</th>
                                    <th width={70}>Grand</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exportRows && exportRows.map((row, i) => {
                                    row.checked = row.checked == false ? false : true
                                    return <tr>
                                        <td>
                                            <label style={{ height: 10, display: "inline-block" }}>{(i + 1)}</label>
                                            <input checked={row.checked} style={{ height: 10, weight: 10, display: "inline-block" }} type={"checkbox"} onChange={(e) => {
                                                row["checked"] = e.target.checked
                                                setExportRows([...exportRows])
                                            }} />
                                        </td>
                                        <td>{row._consignor}</td>
                                        <td>{row._gst_no}</td>
                                        <td>{row.number}</td>
                                        <td>{row.invoice_date}</td>
                                        <td>{parseFloat(row._calc_data.total).toFixed(2)}</td>
                                        <td>{parseFloat(row._calc_data.cgst).toFixed(2)}</td>
                                        <td>{parseFloat(row._calc_data.sgst).toFixed(2)}</td>
                                        <td width={70}>{parseFloat(row._calc_data.grand).toFixed(2)}</td>
                                    </tr>
                                })
                                }

                            </tbody>
                        </Table>
                        {rows.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
                    </div>
                </Modal.Body>
            </Modal>
            <Loader loading={cnoteState.loading || loader} />
            <Container fluid>
                <Row className={"pb-2 bar-menu"}>
                    <Col md={1}>
                        Finacial Year
                        <SelectBox onChange={(value, text) => {
                            console.log("value: ", value)
                            localStorage.year = value
                            getInvoiceNumber(value)
                        }} defaultValue={localStorage.year}
                            ref={yearRef} options={years}
                            keys={{ label: "fin_year", value: "fin_year" }}
                            label="Financial Year" searchable={false} />
                    </Col>
                    <Col md={2}>
                        From
                        <DateBox label="From" ref={fromRef} size={"md"} max={cdate()} onChange={(e) => {
                            toRef.current.value = endOfMonth(new Date(e.target.value))
                            invoiceDateRef.current.value = endOfMonth(new Date(e.target.value))
                        }} />
                    </Col>
                    <Col md={2}>
                        To
                        <DateBox label="From" ref={toRef} max={getYYYMMDD(cdate())} size={"md"} />
                    </Col>
                    <Col md={3} style={{ paddingTop: 16 }}>
                        <Button onClick={getCnotes} variant="success">Search</Button>
                        <Button className={"mx-2"} variant="info" onClick={getInvoice}>Invoice</Button>
                        <Button className={"mx-2"} variant="primary" onClick={fetchDataForEdit}>Edit Data</Button>
                        <Button className={"mx-2"} onClick={fetchDataForExport}>Export</Button>
                    </Col>
                    <Col md={2}>
                        Invoice Date
                        <DateBox label="Invoice Date" ref={invoiceDateRef} max={cdate()} size={"md"} />
                    </Col>
                    <Col md={2}>
                        Invoice No
                        <div className={"d-flex"}>
                            <TextBox ref={invoiceNoRef} style={{ minWidth: 30 }} />
                            <Button onClick={() => { fetchDataByInvoiceNumber(invoiceNoRef.current.value.replace(/^0+/, ''), true) }} className={"mx-2"}>Search..</Button>
                            <Button onClick={saveInvoice} className={"btn-danger"}>Save</Button>
                        </div>
                    </Col>


                </Row>
                <Row className={"pb-2"}>
                    <Col className='company-list' md={2}>
                        <Card className={"p-1"}>
                            <ListGroup horizontal>
                                <ListGroup.Item className={"w-100"}>
                                    <input type={"text"} placeholder={"search here..."}
                                        className={"search-box form-control form-control-sm"} onChange={onSearch} />
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        <div className={"overflow-auto"}>
                            {consignor && consignor.map((data, i) => {
                                //should not remove img key
                                return <List selected={(cnor === data.id)} selectable={"radio"} actions={false} onClick={onListClick} key={i} data={data} keys={["img", "name"]} className={"mb-2 btn"} />;
                            })}
                        </div>
                    </Col>
                    <Col md={10}>
                        <div style={{ height: "80vh", overflowY: "auto" }}>
                            <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                                <thead>
                                    <tr>
                                        <th width={50}><input type={"checkbox"} defaultChecked={true} onChange={(e) => {
                                            setRows([...rows])
                                            rows.map((r) => { r.checked = e.target.checked })
                                            setRows([...rows])
                                        }} />SL No</th>
                                        <th width={100}>Date</th>
                                        <th width={90}>CNote</th>
                                        {/* <th>Consignor</th> */}
                                        <th>Consignee</th>
                                        <th>Place</th>
                                        <th width={70}>Weight</th>
                                        <th width={70}>No of Pieces</th>
                                        <th width={80}>Rate</th>
                                        <th width={70}>Amount</th>
                                        {/* <th colSpan={2} width={30}>Actions</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows && rows.map((row, i) => {
                                        row.checked = row.checked == false ? false : true
                                        if (row.checked) {
                                            totalAmount = parseFloat(totalAmount) + parseFloat(row.amount)
                                        }
                                        row["weight"] = row.weight == 0.25 ? "M" : row.weight
                                        return <NewEleRow key={i}
                                            index={i}
                                            inputRow={row}
                                            onClickRow={onClickRow}
                                            selectable={true}
                                            hidecolums={["_consignor", "invoice"]}
                                            onChange={onRowChange}
                                            checked={row.checked}
                                        />
                                    })
                                    }
                                    <tr>
                                        <td colSpan={7}></td>
                                        <td>Total</td>
                                        <td>{parseFloat(totalAmount).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}></td>
                                        <td><span style={{ fontSize: 10 }}>CGST</span>(<input type="number" min={0}
                                            style={{ width: 30, height: 16, textAlign: "right" }} value={cgst} onChange={(e) => { localStorage.cgst = e.target.value; setCgst(e.target.value) }} />%)</td>
                                        <td>{percentage(totalAmount, cgst)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7}></td>
                                        <td><span style={{ fontSize: 10 }}>SGST</span>(<input type="number" min={0}
                                            style={{ width: 30, height: 16, textAlign: "right" }} value={sgst} onChange={(e) => { localStorage.sgst = e.target.value; setSgst(e.target.value) }} />%)</td>
                                        <td>{percentage(totalAmount, sgst)}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan={7} style={{ padding: "1px" }}>
                                            <input className={"w-100"} ref={descRef} />
                                        </td>
                                        <td>Other</td>
                                        <td style={{ padding: "1px" }}>
                                            <input type="number" min={0} className={"w-100"} value={other} onChange={(e) => { localStorage.other = e.target.value; setOther(e.target.value) }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={7} style={{ padding: "1px" }}>

                                        </td>
                                        <td>Grand</td>
                                        <td><b>{calcTax(totalAmount, percentage(totalAmount, sgst), percentage(totalAmount, cgst), other)}</b></td>
                                    </tr>
                                </tbody>
                            </Table>
                            {rows.length === 0 && <h6 className="text-center mt-5 text-info">No record found</h6>}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );

}
export default Bills;