import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import { ListGroup } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import { Modal } from 'react-bootstrap';
import ImageResize from './ImageResize';
import { v4 as uuid } from 'uuid';

const Image = ({ image, cropShape, name }) => {
    let imgRef = React.createRef()
    let [imgEvent, setImgEvent] = useState(image);
    let [img, setImg] = useState(null);
    let [show, setShow] = useState(false);
    let aspect = cropShape == "round" ? 4 / 4 : 4 / 3;
    const unique_id = uuid();
    return (
        <>

            <Avatar
                style={{ height: "60px", width: "60px" }}
                alt="Profile not found"
                src={img}
            />
            <input hidden  id={unique_id} type="file" name={name} onChange={(e) => {
                imgRef.current = e.target
                setImgEvent(e);
                setShow(true)
            }} accept="image/*" />
            <label htmlFor={unique_id}><Icon.Pencil /></label>
            <Modal centered show={show} size="sm" onHide={() => {
                setShow(false)
            }}>
                <Modal.Body style={{ minHeight: 300 }}>
                    <ImageResize cropShape={cropShape} aspect={aspect} fileEvent={imgEvent} callback={(ci) => {
                        setImg(ci);
                        fetch(ci)
                            .then(res => res.blob())
                            .then(blob => {
                                console.log("blob file:", blob)
                                let file = new File([blob], name + ".jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
                                let container = new DataTransfer();
                                container.items.add(file);
                                console.log(imgEvent.target)
                                imgEvent.target.files = container.files;
                                // document.getElementById(unique_id).value = blob;
                                setImg(blob);
                            })
                        setShow(false)
                    }} />
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Image