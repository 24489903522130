import React, {useRef} from 'react';
import ReactToPrint from 'react-to-print';
import * as Icon from 'react-bootstrap-icons';
import ExportExcel from "./ExportExcel";
import './main.css'

export const Print = (props) => {
    const isShowPrint = props.hasOwnProperty("print") && props.print === false ? false : true
    const isShowDwd = props.hasOwnProperty("dwd") && props.dwd === false ? false : true
    let title = props.title|"print"
    const componentRef = useRef();
    const getBTn = () => {
        return isShowPrint ? (<span className={"btn"}><Icon.Printer style={{fontSize:"16px"}} title={"Print"}/></span>) : <span></span>
    }
    return (
        <div className={"prin-area"}>
            <div className={"d-flex justify-content-end"}>
                <ReactToPrint documentTitle={title}
                    trigger={getBTn}
                    content={() => componentRef.current}
                />
                <ExportExcel>
                    {isShowDwd && <Icon.Download style={{fontSize:"16px"}}/>}
                </ExportExcel>
            </div>
            <div className={"pt-1"} ref={componentRef}>{props.children}</div>
        </div>
    );
};
