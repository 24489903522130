import React from "react";
import { Table } from "@mui/material";
import { Row, Col, Container, Button } from 'react-bootstrap'
import SelectBox from "../float-inputs/SelectBox";
import DateBox from "../float-inputs/DateBox";
import TextBox from "../float-inputs/TextBox";
import Loader from "../loader/Loader";
import './main.css';
import { useSelector, useDispatch } from 'react-redux';
import NewEleRow from './NewEleRow';
import { newRow } from "./NewEleRow";

import {
    CNOTE_FAILED,
    GET_CNOTE_REQUEST,
    GET_TRANSPORT_REQUEST,
    PATCH_CNOTE_REQUEST,
    POST_CNOTE_REQUEST,
} from '../../redux/types';
import ErrorParser from "../ErrorParser";
import { cdate, getYYYMMDD } from "../date/dates";
//R60115559

function EditTable(props) {
    const isReloadedref = React.useRef(false);
    const dateRef = React.useRef(getYYYMMDD());
    const trasportRef = React.useRef();
    const searchRef = React.useRef();
    let dispatch = useDispatch();
    let cnoteState = useSelector(state => state.cnotes)
    const transportSate = useSelector(state => state.transport)
    const [rows, setRows] = React.useState(cnoteState.data)
    const [newEditRow, setNewEditRow] = React.useState({ ...newRow(cdate()) })
    const [seleDate, setSeleDate] = React.useState(cdate())

    React.useEffect(() => {
        dispatch({ type: GET_CNOTE_REQUEST, payload: 0 });
        dispatch({ type: GET_TRANSPORT_REQUEST, payload: 0 });
        return function cleanedUp() {
            console.log("cleaned up")
        }
    }, [])

    React.useEffect(() => {
        // if (isReloadedref.current !== cnoteState.data.length) {
        setRows([...cnoteState.data])
        // }
        isReloadedref.current = cnoteState.data.length
        return function cleanup() {
            console.log("cnote cleaned up")
        }
    }, [cnoteState.data])
    //, ...[newRow(dateRef.current.value)

    React.useEffect(() => {
        if (dateRef.current) {
            setNewEditRow({ ...newRow(dateRef.current.value) })
        }
        return function cleanup() {
            console.log("cnote cleaned up date")
        }
    }, [seleDate])

    const isEmpty = (data, key) => {
        return data[key] === undefined || data[key] === "" || data[key] === 0 || data[key].length === 0;
    }
    function forceUpdate() {
        setRows(prev => {
            return [...prev]
        })
    }
    const onClickRow = (data, action, callback) => {
        data["company"] = 1;
        data["transp"] = trasportRef.current.value
        let errors = []
        console.log("Submitted data:", data)
        if (!data.id) {
            Object.keys(newRow()).map((key) => {
                if (key === "autofocus" || key === "invoice") return 0
                if (isEmpty(data, "consignee") && isEmpty(data, "_consignee")) {
                    errors.push(key)
                } else if (isEmpty(data, "place") && isEmpty(data, "_place")) {
                    errors.push(key)
                } else if (isEmpty(data, key) && !["place", "_place", "consignee", "_consignee"].includes(key)) {
                    errors.push(key)
                }
                return 0
            })
        }
        if (errors.length > 0) {
            dispatch({ type: "CNOTE_FAILED", data: { error: true, message: "Please fill all fields" + errors.join(",") } });
            data["editable"] = true
            return false
        }
        if (!data.id) {
            rows.pop()
            dispatch({
                type: POST_CNOTE_REQUEST, payload: data, callback: (res) => {
                    if (res.error) {
                        callback(res)
                    } else {
                        callback(res)
                        dispatch({ type: "CNOTE_FAILED", data: { error: false, message: `${res.c_note} successfully added` } });
                    }
                    forceUpdate()
                }
            });
        } else {
            dispatch({
                type: PATCH_CNOTE_REQUEST, payload: data, callback: (res) => {
                    if (res) {
                        callback(res)
                        dispatch({ type: "CNOTE_FAILED", data: { error: false, message: `${res.c_note} successfully updated` } });
                    } else {
                        dispatch({ type: "CNOTE_FAILED", data: { error: true, message: `Updation failed :${res.message}` } });
                    }
                    forceUpdate()
                }
            });
        }

    }
    const search = (e) => {
        let v = searchRef.current.value
        dispatch({ type: GET_CNOTE_REQUEST, payload: `cnote/search/0/${v}/` })
    }
    return (
        <>
            <Loader loading={cnoteState.loading} />
            <Container fluid>
                <Row className={"pb-2"}>
                    <Col md={2}>
                        <TextBox ref={searchRef}  label={"Search c note"} />
                    </Col>
                    <Col md={1}>
                        <Button onClick={search}>Search</Button>
                    </Col>
                    <Col md={2}>
                        <DateBox type={"date"} ref={dateRef} value={seleDate} size="md" onChange={(e) => {
                            setSeleDate(e.target.value);
                            setNewEditRow({ date: seleDate })
                            forceUpdate()
                        }} />
                    </Col>
                    <Col md={2}>
                        <SelectBox onChange={(value, text) => {
                            localStorage.traspid = value
                        }} defaultValue={localStorage.traspid}
                            ref={trasportRef} options={transportSate.data}
                            keys={{ label: "transp", value: "id" }}
                            label="Transport" searchable={false} />
                    </Col>
                    <Col md={2}>
                        <Button id="newRow" onClick={() => {
                            cnoteState.data.push(newRow(dateRef.current.value))
                            setRows([...cnoteState.data])
                        }}>New Row</Button>
                    </Col>
                    <Col md={3}>
                        <ErrorParser className={"mt-2 " + (cnoteState.success ? "text-success" : "text-danger")} message={cnoteState.message} />
                    </Col>
                </Row>
                <div style={{ height: "80vh", overflowY: "auto" }}>
                    <Table striped={"true"} bordered={"true"} hover={"true"} size="sm">
                        <thead>
                            <tr>
                                <th width={50}>SL No</th>
                                <th width={100}>Date</th>
                                <th width={90}>CNote</th>
                                <th>Consignor</th>
                                <th>Consignee</th>
                                <th>Place</th>
                                <th width={70}>Weight</th>
                                <th width={70}>No of Pieces</th>
                                <th width={70}>Rate</th>
                                <th width={70}>Amount</th>
                                <th colSpan={2} width={30}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows && rows.map((row, i) => {
                                return (

                                    <NewEleRow key={i}
                                        index={i}
                                        date={row.date}
                                        inputRow={row}
                                        onClickRow={onClickRow}
                                        actions={true}
                                        hidecolums={["invoice"]}
                                        
                                    />

                                )
                            })}
                            <NewEleRow key={rows.length}
                                index={rows.length}
                                date={seleDate}
                                inputRow={newEditRow}
                                onClickRow={onClickRow}
                                actions={true}
                                hidecolums={["invoice"]}
                            />
                        </tbody>
                    </Table>
                </div>
            </Container>
        </>
    );
}
export default EditTable;