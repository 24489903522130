import React from "react";
import { Table } from "@mui/material";
import './main.css';

function BillTable({ company, consignor, invoice, rows }) {
    let totalAmount = 0
    const [showGst, setShowGst] = React.useState(true)

    const percentage = (total, percentage) => {
        return parseFloat((total / 100) * percentage).toFixed(2)
    }
    const calcTax = (totalAmount, sgstPerc, cgstPerc, other) => {
        let t = parseFloat(totalAmount) + parseFloat(sgstPerc) + parseFloat(cgstPerc) + parseFloat(other ? other : 0)
        return t.toFixed(2)
    }
    const padZero = (number) => {
        return String(parseInt(number)).padStart(3, '0')
    }
    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th colSpan={7} style={{ border: "none" }}>
                        <div className={"text-center pb-3"}><h6>TAX INVOICE</h6></div>
                    </th>
                </tr>
                <tr>
                    <td colSpan={7} style={{ border: "none" }}>
                        <div style={{ textAlign: "center" }}>
                            <p>{company.name}</p>
                            <div className="pb-1">{company.address1},{company.address2},{company.place}-{company.pin}</div>
                            <div className="pb-1"> GST ID:{company.gst_id} PAN: {company.pan}</div>
                            <div className="pb-1"> Phone:{company.phone}, Email:{company.email}</div>
                        </div>
                        <div className="d-flex">
                            <div className="mr-auto w-100" style={{ textAlign: "left" }}>
                                <p> To,</p>
                                <div className="pb-1">
                                    {consignor.name}
                                </div>
                                <div className="pb-1">
                                    {consignor.address1}
                                </div>
                                <div className="pb-2">
                                    {consignor.address2}, GST  {consignor.gst_id}
                                </div>
                            </div>
                            <div className="w-100" style={{ textAlign: "right" }}>
                                <div className="pb-1"> Invoice No: {padZero(invoice.number)}</div>
                                <div className="pb-1">Invoice Date: {invoice.invoice_date}</div>
                                <div className="pb-1">HSN: 9968</div>
                                <div className="pb-1">SAC: 996812</div>
                            </div>
                        </div>
                        <div className={"p-2"} style={{ textAlign: "center" }}>From: {invoice.from_date}-To:{invoice.to_date}</div>
                    </td>
                </tr>
                <tr>
                    <th width={50}>SL NO</th>
                    <th width={85}>DATE</th>
                    <th width={70}>CNOTE NO</th>
                    <th>CONSIGNEE</th>
                    <th>DESTINATION</th>
                    <th width={80}>WEIGHT</th>
                    <th width={80}>AMOUNT</th>
                </tr>
            </thead>
            <tbody>
                {rows && rows.map((row, i) => {
                    totalAmount = parseFloat(totalAmount) + parseFloat(row.amount)
                    row["weight"] = row.weight == 0.25 ? "M" : row.weight
                    return <tr>
                        <td width={50}>{(i + 1)}</td>
                        <td width={85}>{(row.date)}</td>
                        <td width={70}>{(row.c_note)}</td>
                        <td>{(row._consignee)}</td>
                        <td>{(row._place)}</td>
                        <td width={80}>{(row.weight)}</td>
                        <td width={80}>{(row.amount)}</td>
                    </tr>
                })
                }

                <tr>
                    <td colSpan={5} style={{textAlign:"right" }}>
                        <div class="btn-group btn-group-sm" role="group">
                            <label style={{ width: 300,fontSize:10,textAlign:"right" }}>Show GST</label>
                            <input type="checkbox" style={{ height: 12}} checked={showGst} onChange={(e) => { setShowGst(e.target.checked) }} />
                        </div>
                    </td>
                    <td>Total</td>
                    <td>{parseFloat(totalAmount).toFixed(2)}</td>
                </tr>
                {showGst && <>
                    <tr>
                        <td colSpan={5}></td>
                        <td>CGST({parseFloat(invoice.cgst).toFixed(0)}%)</td>
                        <td>{percentage(totalAmount, invoice.cgst)}</td>
                    </tr>
                    <tr>
                        <td colSpan={5}></td>
                        <td>SGST({parseFloat(invoice.sgst).toFixed(0)}%)</td>
                        <td>{percentage(totalAmount, invoice.sgst)}</td>
                    </tr>
                    <tr>
                        <td colSpan={5} style={{ padding: "1px" }}>
                            {invoice.description}
                        </td>
                        <td>Other</td>
                        <td style={{ padding: "1px" }}>{invoice.sess}</td>
                    </tr>
                    <tr>
                        <td colSpan={5} style={{ padding: "1px" }}>
                            <input className={"w-100"} />
                        </td>
                        <td>Grand</td>
                        <td>{calcTax(totalAmount, percentage(totalAmount, invoice.sgst), percentage(totalAmount, invoice.cgst), invoice.sess)}</td>
                    </tr></>}
                <tr>
                    <td colSpan={7} style={{ textAlign: "left" }}>
                        <div>BANK NAME: {company.bank_name}</div>
                        <div>ACCNO:  {company.acc_no}</div>
                        <div>IFC CODE:  {company.ifsc}</div>
                        <div>BR.: {company.branch}</div>
                        <div>ACC NAME: {company.acc_name}</div>
                    </td>

                </tr>
                <tr>
                    <td colSpan={5} style={{ textAlign: "left" }}>
                        <div>Please Issue Cheques/DDs in favour of {company.acc_name}</div>
                    </td>
                    <td colSpan={2} style={{ textAlign: "right" }}>For {company.name}</td>
                </tr>
            </tbody>
        </Table>
    );
}

export default BillTable;