import React from 'react'
import "./company.css";
import { Button } from 'react-bootstrap';
import TextBox from "../../components/float-inputs/TextBox";
import XForm from "../../components/float-inputs/XForm";
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { PATCH_COMPANY_REQUEST, POST_COMPANY_REQUEST } from '../../redux/types';
function CompanyForm(props) {
    let { data, userId } = props
    let [step, setStep] = React.useState(0);
    let [prevData, setPrevData] = React.useState(data);
    let companySaga = useSelector(state => state.company)
    let dispatch = useDispatch()
    console.log("userId:" + userId);
    return (
        <>
            <p>{companySaga.message}</p>
            <div className='d-flex justify-content-between pt-2'>
                <div>Enter Company Details</div><div><Loader loading={companySaga.loading} /></div>
            </div>
            {step === 0 &&
                <XForm name={"form1"} data={prevData} callback={(e, firstData) => {
                    setStep(1); setPrevData(firstData)
                }
                }>
                    <TextBox maxLength={100} label={"Name"} name={"name"} />
                    <TextBox maxLength={200} label={"Address 1"} name={"address1"} />
                    <TextBox maxLength={200} label={"Address 2"} name={"address2"} />
                    <TextBox maxLength={200} label={"Place"} name={"place"} />
                    <TextBox maxLength={30} label={"email"} name={"email"} />
                    <TextBox maxLength={15} label={"Phone"} name={"phone"} />
                    <TextBox maxLength={30} label={"Fax"} name={"fax"} />
                    <TextBox maxLength={20} label={"Pin"} name={"pin"} />
                    <div className='d-flex pt-2 justify-content-between'>
                        <Button disabled="true">Prev</Button>
                        <Button type={"submit"}>Next</Button>
                    </div>
                </XForm>}
            {step == 1 &&
                <XForm name={"form2"} data={data} callback={(e, data) => {
                    let combineData = { ...prevData, ...data }
                    console.log("Combine data:", userId)
                    if (e.target.name === "form2") {
                        if (userId !== null) {
                            dispatch({
                                type: PATCH_COMPANY_REQUEST,
                                payload: combineData,
                                id: userId
                            })
                        } else {
                            dispatch({
                                type: POST_COMPANY_REQUEST,
                                payload: combineData,
                            })
                        }
                    }
                }}>
                    <TextBox maxLength={30} label={"gst_id"} name={"gst_id"} />
                    <TextBox maxLength={100} label={"Pan"} name={"pan"} />
                    <TextBox maxLength={100} label={"Bank Name"} name={"bank_name"} />
                    <TextBox maxLength={100} label={"Account Number"} name={"acc_no"} />
                    <TextBox maxLength={10} label={"IFSC Code"} name={"ifsc"} />
                    <TextBox maxLength={200} label={"Branch"} name={"branch"} />
                    <TextBox maxLength={100} label={"Account name"} name={"acc_name"} />
                    <TextBox maxLength={5} label={"Other Payment"} name={"other_payment"} />
                    <div className='d-flex justify-content-between pt-2'>
                        <Button onClick={() => { setStep(0) }}>Prev</Button>
                        <Button type={"submit"}>Submit</Button>
                    </div>
                </XForm>}
        </>
    )
}

export default CompanyForm